<template>
  <div class="branch-shop-options">
    <PageTitle :title="$t('page.branchManage.title')" hideBtn />

    <section v-if="useRecentShops">
      <p class="text-primary-100 text-md font-medium pb-[5px]">{{ $t('branchManage.recentVisit.title')}}</p>
      <div
        v-if="!recentShopsData.length"
        class="text-gray-50 text-sm font-medium py-[10px]"
      >
        {{ $t('branchManage.emptyRecentVisit') }}
      </div>
      <div v-else class="branch-group">
        <div
          v-for="item in recentShopsData"
          :key="item.id"
          :data-testid="`shop-recent-${item.name}`"
          class="branch-item"
          @click="selectBranch(item.id)"
        >
          <el-card
            :class="{ 'branch-selected': shop === item.id }"
            shadow="never"
          >
            <div slot="header">
              <img
                class="d-inline-block align-text-bottom mr-2"
                src="@/assets/icon/icon-shop.svg"
              >
              <span>{{ item.name }}</span>
            </div>
            <div class="branch-info">
              <p>{{ item.phone || "-" }}</p>
              <p>{{ item.address || "-" }}</p>
            </div>
          </el-card>
        </div>
      </div>

      <hr>
    </section>

    <section>
      <div class="branch-group">
        <div
          v-for="item in userAllowShop"
          :key="item.id"
          class="branch-item"
          :data-testid="`shop-${item.name}`"
          @click="selectBranch(item.id)"
        >
          <el-card
            :class="{ 'branch-selected': shop === item.id }"
            shadow="never"
          >
            <div slot="header">
              <img
                class="d-inline-block align-text-bottom mr-2"
                src="@/assets/icon/icon-shop.svg"
              >
              <span>{{ item.name }}</span>
            </div>
            <div class="branch-info">
              <p>{{ item.phone || "-" }}</p>
              <p>{{ item.address || "-" }}</p>
            </div>
          </el-card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import configuration from '@/configuration'
import { FindShopPoint } from '@/api/shopPoint'
import { FindShopRebate } from '@/api/rebate'
import { usePermissions } from '@/use/permissions'
import { useStorage } from '@vueuse/core'
import { get, uniq, find, map, compact } from 'lodash'
import store from '@/store'
import localStoreKeys from '@/config/localStoreKeys'
export default defineComponent({
  name: 'BranchShopOptions',
  setup () {
    const { checkAction } = usePermissions()
    const userAllowShop = computed(() => map(get(store, 'getters.userAllowShop'), shop => {
      return {
        ...shop,
        address: shop.address ? shop.address.replace(/,/g, '') : '-',
      }
    }))
    const useRecentShops = computed(() =>
      get(store.getters, 'app/useRecentShops'),
    )
    const maxRecentShops = 6
    const recentShops = useStorage(localStoreKeys.recentShops, [])
    const recentShopsData = computed(() => {
      return compact(
        map(recentShops.value, (shopId) =>
          find(userAllowShop.value, { id: shopId }),
        ),
      )
    })

    const purgeRecentShops = () => {
      // 列出 recentShops 中不存在於 userAllowShop 中的店家
      const purgedShops = compact(
        map(recentShops.value, (shopId) => {
          if (!find(userAllowShop.value, { id: shopId })) return shopId
        }),
      )

      recentShops.value = recentShops.value.filter(
        (shopId) => !purgedShops.includes(shopId),
      )
    }

    const getMarketingPermission = async (shopId) => {
      let marketingItem = {
        classTicket: false,
        coupon: false,
        point: false,
        cashback: false,
      }

      let pointCheck = false
      if (checkAction('admin.shopPoint.page')) {
        const [res, err] = await FindShopPoint({ shopId })
        if (res) pointCheck = get(res, 'pointClientId') !== null
        if (err) pointCheck = false
      }
      let cashbackCheck = false
      if (checkAction('admin.shopCashback.page')) {
        const [res, err] = await FindShopRebate({ shopId })
        if (res) cashbackCheck = Object.keys(res).length !== 0
        if (err) cashbackCheck = false
      }

      marketingItem = {
        classTicket: checkAction('admin.classTicket.page'),
        coupon:
          checkAction('admin.coupon.page') ||
          checkAction('admin.couponExchange.page'),
        point: pointCheck,
        cashback: cashbackCheck,
      }

      store.commit('permission/SET_MARKETINGITEM_PERMISSIONS', {
        key: 'marketingItem',
        data: marketingItem,
      })
    }

    const recordRecentShop = (shopId) => {
      if (!useRecentShops.value) return
      if (recentShops.value.length >= maxRecentShops) {
        recentShops.value.pop()
        recentShops.value.unshift(shopId)
      } else {
        recentShops.value.unshift(shopId)
      }
      recentShops.value = uniq(recentShops.value)
    }

    onMounted(() => {
      if (useRecentShops.value) purgeRecentShops()
    })

    return {
      getMarketingPermission,
      recentShops,
      userAllowShop,
      recentShopsData,
      recordRecentShop,
      useRecentShops,
      purgeRecentShops,
    }
  },
  computed: {
    ...mapGetters(['shop', 'shopList', 'appTheme', 'isOverseasShop']),
  },
  async created () {
    await this.getMe()
  },
  methods: {
    ...mapActions('user', ['getMe']),
    async selectBranch (shopId) {
      const success = await this.$store.dispatch('auth/changeShop', {
        shopId,
        message: this.$message,
      })
      this.recordRecentShop(shopId)
      if (!success) return
      await this.getMarketingPermission(shopId)
      await this.$store.dispatch('app/setSidebarPage', 2)
      this.$store.dispatch('shop/getGlobalShopData', { shopId })
      this.$store.commit('RESET_HOME_PAGE_STORE')
      if (this.appTheme === 'righttime') {
        this.$router.push({ name: 'MembersManage' })
      } else if (this.isOverseasShop) {
        this.$router.push({ name: 'ReservationCalendar' })
      } else {
        this.$router.push({ name: 'HomePage' })
      }
    },
  },
})
</script>

<style scoped lang="scss">
$primary-1: var(--primary-100);

.branch-shop-options {
  .branch-group {
    @apply flex flex-wrap flex-row;
  }
  .branch-item {
    flex-basis: 33%;
    box-sizing: border-box;
    padding-right: 20px;
    padding-bottom: 20px;
    min-width: 468px;
  }
  .branch-info {
    @apply text-gray-80;
  }
  .el-card {
    box-sizing: border-box;
    border: 2px solid transparent;
    height: 100%;
    padding: 12px 16px;
    cursor: pointer;
    &:hover {
      @apply border-[2px] border-primary-100;
      // border-color: lighten($color: $primary-1, $amount: 10);
    }
    &.branch-selected {
      border-color: var(--primary-100);
    }
    &::v-deep {
      .el-card__header {
        padding: 0px;
        margin-bottom: 24px;
        border-bottom: none;
      }
      .el-card__body {
        padding: 0px;
      }
    }
  }
}
</style>
